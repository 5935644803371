import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import update from 'immutability-helper'
import memoize from 'memoize-one'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string';
import { pick as _pick } from 'lodash';
import {
  faArrowRight,
  faCaretDown,
  faTimes,
  faFilter,
  faBalanceScale,
  faCalendarAlt,
  faSpinner
} from "@fortawesome/fontawesome-free-solid";
import { faClock,faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { getSexesByKinds, getStockCategoriesByKinds } from "../../utils/Lots";
import FilterRangeAge from "../FilterRangeAge/FilterRangeAge";
import FilterRange from "../FilterRange/FilterRange";
import { subTitles } from "../../constants";
import {
  momentTz,
  limitTextLength,
  calculateLimitTextLength,
} from "../../utils";
import {
  isSeller,
  updateMainData,
  updateInitialData,
  isAllowedToAddNewLot,
} from "../../utils/UpdateData";
import {
  getSectionHeaderTitle,
  isAvailableAuction,
} from "../../utils/Auctions";
// import { getKindOptions } from "../../utils/FindFieldKind";
import { filterAuctions, sortAuctions } from "../../utils/Filter";
import FilterKind from "../FilterKind/FilterKind";
import FilterMultiSelect from "../FilterMultiSelect/FilterMultiSelect";
import Modal from "react-modal";
import ReactHtmlParser from "react-html-parser";
import Api from "../../services/api";
//import '../AuctionList/AuctionList.css'
//import '../../../src/'

import bookImage from "../../assets/img/book.png";
import cowImage from "../../assets/img/cowauction.png";
import lambImage from "../../assets/img/lambauction.png";
import goatImage from "../../assets/img/goat-image.png";
import hammerImage from "../../assets/img/hammer.png";
// import farmgateimage from "../../assets/img/farmbuy-logo-black.png";
import poweredByFarmgate from '../../assets/img/poweredByFarmgate.png'


class AuctionLits extends Component {
  constructor(props) {
    super(props);
    document.title = "FarmGate";
    this.state = {
      filters: {
        screen: this.getScreen(),
        breed: this.getSavedFilterValue("breed", []),
        sex: this.getSavedFilterValue("sex", []),
        age: this.getSavedFilterValue("age", []),
        weight: this.getSavedFilterValue("weight", []),
        showMode: this.getSavedFilterValue("showMode", "all"),
        stockCategory: this.getSavedFilterValue("stockCategory", []),
        state: this.getSavedFilterValue("state", []),
        selectedagents: this.getSavedFilterValue("selectedagents", []),
        agency: this.getSavedFilterValue("agency", []),
      },
      formData : {
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
      },
      errors: {
        email: '',
        phone: '',
        firstName: '',
        lastName:''
      },
      modalIsOpen: false,
      showModalForm : false,
      modalAuction: null,
      modalTextIsOpen: false,
      showComingSoon: false,
      showLoader: true,
      showContent: true,
      showValidDataMessage : false,
      kinds: [],
    };
    this.cancelSource = axios.CancelToken.source();
    this.getFilteredAuctions = memoize(this.getFilteredAuctions);
    this.groupAuctionsByDate = memoize(this.groupAuctionsByDate);
    this.changeShowMode = this.changeShowMode.bind(this);
    this.filterChangeHandler = this.filterChangeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openTextModal = this.openTextModal.bind(this);
    this.closeTextModal = this.closeTextModal.bind(this);
    this.handleSubmit =  this.handleSubmit.bind(this);
   // this.handleChange = this.handleChange.bind(this);
    this.checkInterval = null
    
  }

  componentDidMount() {
    this.checkInterval = setInterval(() => { updateMainData() }, 3000)
    window.parent.parent.postMessage(`Update link`, "*");
    window.parent.parent.postMessage(`Scroll up`, "*");
    window.scroll(0, 0);
    window.addEventListener("message", (message) => {
      if (
        message &&
        message.data &&
        message.data.match &&
        message.data.match("Get iframe height")
      ) {
        const height =
          document.getElementsByClassName("app-route")[0].offsetHeight;
        window.parent.parent.postMessage(`iframe height=${height || 920}`, "*");
      } else if (
        message &&
        message.data &&
        message.data.match &&
        message.data.match("Result url params for redirect=")
      ) {
        const params = message.data.slice(
          "Result url params for redirect=".length
        );

        this.props.history.push(params);
      }
    });
    this.setState({
      showContent: false,
    });
    this.updateData().then(() => {
      this.setState({
        showContent: true,
      });
    });
    this.getKinds();
  const setOptions = this.setOptions();
    this.setState(
      update(this.state, {
        filters: {
          stockCategory: { $set: setOptions.stockCategory? [setOptions.stockCategory] : [] },
          sex: { $set: setOptions.sex ? [setOptions.sex]: [] },
          showMode: { $set: setOptions.category ? setOptions.category: 'all'  },
          state: { $set:setOptions.state ? [setOptions.state]: [] },
          age: { $set: setOptions.minage && setOptions.maxage ? [setOptions.minage,setOptions.maxage] : [] },
          breed: { $set: setOptions.breed ? [setOptions.breed] : [] },
          weight: { $set: setOptions.minweight && setOptions.maxweight ? [setOptions.minweight,setOptions.maxweight] : []},
          selectedagents: { $set: setOptions.listingagent? [setOptions.listingagent] : [] },
          agency:{$set: setOptions.listingagency ? [setOptions.listingagency] : []}
        },
      })
    );
    // if (this.state.modalTextIsOpen) {
    //   this.setState({
    //     modalTextIsOpen: false,
    //   });
    // }
  }
  setOptions(){
  let params = queryString.parse(window.location.search)
 return params
  }
  getKinds() {
    Api.getKinds().then((data) => {
      this.setState({
        kinds: data.kinds,
      });
    });
  }
  async updateData() {
    await updateInitialData();
    await updateMainData();
    setTimeout(() => {
      this.setState({
        showComingSoon: true,
        showLoader: false
      });
    }, 2000)
  }

  componentWillUnmount() {
   clearInterval(this.checkInterval)
    window.removeEventListener("message", (message) => {
      if (
        message &&
        message.data &&
        message.data.match &&
        message.data.match("Get iframe height")
      ) {
        const height =
          document.getElementsByClassName("app-route")[0].offsetHeight;
        window.parent.parent.postMessage(`iframe height=${height || 920}`, "*");
      } else if (
        message &&
        message.data &&
        message.data.match &&
        message.data.match("Result url params for redirect=")
      ) {
        this.props.history.push(
          message.data.slice("Result url params for redirect=".length)
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp("filters", [this.state.filters], "user");
    }
    let scrollPosition = parseInt(window.localStorage.getItem('upcomingAuctionsListScrollPosition')) || 0
    let countOfAuctions = parseInt(window.localStorage.getItem('upcomingAuctionsListCount')) || 0
    if (countOfAuctions !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.props.auctions.length === countOfAuctions) {
        window.localStorage.clear()
      }
    }
  }

  getScreen() {
    return "upcomingAuctionsList";
  }

  getSavedFilterValue(key, defaultValue = []) {
    const screen = this.getScreen();
    const thisFilter = this.props.filters.find(
      (filterSet) => filterSet.screen === screen
    );
    let value;
    if (thisFilter) {
      value = thisFilter[key];
    }
    return value || defaultValue;
  }

  getFilteredAuctions(auctions, filters) {
    const filteredAuctions = auctions.filter((auction) => {
      return filters.showMode === "all"
        ? true
        : auction.kindData.type.toLowerCase() === filters.showMode.toLowerCase();
    });
    return filterAuctions(filteredAuctions, filters);
  }

  openLive(auction) {
    if (window.location.host.includes("test-site")) {
      window.open(
        `https://test-webapp.farmgatetest.com.au/#/auction-live/${auction.searchNumber}`
      );
    } else if (window.location.host.includes("localhost")) {
      window.open(
        `http://localhost:4000/#/auction-live/${auction.searchNumber}`
      );
    } else {
      window.open(
        `https://live.farmgateauctions.com.au/#/auction-live/${auction.searchNumber}`
      );
    }
  }

  clearFilter() {
    this.setState(
      update(this.state, {
        filters: {
          showMode: { $set: "all" },
          state: { $set: [] },
          age: { $set: [] },
          sex: { $set: [] },
          breed: { $set: [] },
          weight: { $set: [] },
          stockCategory: { $set: [] },
          selectedagents: { $set: [] },
          agency:{ $set: [] }
        }
      }),
      
    );
  }

  getAuctionStatus(auction) {
    let status;
    switch (auction.state) {
      case "closed":
        status = "Closed";
        break;
      case "open":
        status = "Open";
        break;
      case "live":
        status = "Live";
        break;
      case "future":
      default:
        status = "Upcoming";
        break;
    }
    return status;
  }

  getAuctionState(auction) {
    return auction.location.state.join("/");
  }

  getAuctionType(auction) {
    switch (auction.auctionType) {
      case "simultaneous":
        return "SIM";
      case "sequential":
        return "SEQ";
      default:
        return "";
    }
  }

  goToLink(auction) {
    // window.location.hash = window.location.hash.substr(0, window.location.hash.length) + `/${auction.kindData.title}/${auction.searchNumber}`
    // window.parent.parent.postMessage(
    //   `Add params=${auction.kindData.title}/${auction.searchNumber}`,
    //   "*"
    // );
    return `/main/upcoming/${auction.kindData.title}/${auction.searchNumber}`;
  }

  addNewLink(auction) {
    return `/main/add/new/${auction.kindData.title}/${auction.searchNumber}`;
  }

  filterChangeHandler(filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value },
      }),
    });
  }

  groupAuctionsByDate(auctions) {
    const groupedAuctions = sortAuctions(auctions, "Date").reduce(
      (r, auction) => {
        const title = getSectionHeaderTitle(auction.liveAt);
        let group = r.find((gr) => gr.title === title);
        if (!group) {
          group = { title: title, auctions: [] };
          r.push(group);
        }
        group.auctions.push(auction);
        return r;
      },
      []
    );
    return groupedAuctions;
  }

  changeShowMode(showMode) {
    this.setState({
      filters: update(this.state.filters, {
        showMode: { $set: showMode },
      }),
    });
  }

  openModal(auction) {
    document.body.style.overflow = "hidden";
    const iframe = document.querySelector("iframe");
    const header = document.querySelector("#header_container");
    const title = document.querySelector(".page_title_ctn");
    const text = document.querySelector(".top_text");
    const overlay = document.querySelector(".overlayForPrewiev");
    if (overlay) {
      overlay.style.maxHeight = window.screen.height;
    }
    if (iframe) {
      iframe.style.position = "fixed";
      iframe.style.top = "0";
      iframe.style.left = "0";
    }
    if (header) {
      header.style.zIndex = "0";
      header.style.position = "relative";
    }
    if (title) {
      title.style.zIndex = "0";
    }
    if (text) {
      text.style.display = "none";
    }
    this.setState({
      modalAuction: auction,
      modalIsOpen: true,
    });
  }

  closeModal(auction) {
    document.body.style.overflow = "scroll";
    const iframe = document.querySelector("iframe");
    const header = document.querySelector("#header_container");
    const title = document.querySelector(".page_title_ctn");
    const text = document.querySelector(".top_text");
    if (iframe) {
      iframe.style.position = null;
      iframe.style.top = null;
      iframe.style.left = null;
    }
    if (header) {
      header.style.zIndex = null;
      header.style.position = null;
    }
    if (title) {
      title.style.zIndex = null;
    }
    if (text) {
      text.style.display = null;
    }
    this.setState({
      modalAuction: null,
      modalIsOpen: false,
    });
  }

  openTextModal(e,showModalForm = false) {
    window.parent.parent.postMessage(`Scroll to modal`, "*");
    if (!this.state.modalTextIsOpen) {
      this.setState({
        modalTextIsOpen: true,
        showModalForm : showModalForm 
      });
    }

  }
  canOpenCatalogue = (auction) => {
    if (auction.privateSale) {
      return true;
    }
    return auction.lotCount > 0 || auction.withdrawnCount > 0;
  };

  closeTextModal() {
    if (this.state.modalTextIsOpen) {
      this.setState({
        modalTextIsOpen: false,
        showModalForm: false
      });
    }

  }

  navigateToAddLot(auction) {
    switch (this.props.userStatus) {
      case "partially-approved":
        this.props.showModal({
          message: "Please complete your profile to Add Lot",
          buttons: [
            {
              text: "Cancel",
            },
            {
              text: "Update",
              onPress: () => {
                this.props.history.push(`/main/profile`);
              },
            },
          ],
        });
        break;
      case "partially-approved:updated":
        this.props.showModal({
          message:
            "Your application is under review and should be approved in the next 24-hours. Please contact us if you need immediate access to Add a Lot",
          buttons: [
            {
              text: "OK",
            },
          ],
        });
        break;
      default:
        this.props.history.push(this.addNewLink(auction));
        break;
    }
  }

  isFutureAuctionExist(auctions) {
    return auctions.find((auction) => {
      return (
        auction.state === "future" ||
        auction.state === "open" ||
        auction.state === "live"
      );
    });
  }

  canAddLot(auction) {
    return isAvailableAuction(auction);
  }
  
  canOpenCatalogue = (auction) => {
    if (auction.privateSale) {
      return true;
    }
    return auction.lotCount > 0 || auction.withdrawnCount > 0;
  };
  showAdditionalDateInfo = (auction) => {
    return (
     
      auction.studSale &&
      auction.privateSale &&
      auction.additionalDateInfo &&
      auction.additionalDateInfo.title &&
      auction.additionalDateInfo.date
    );
  };

  renderItem(auction) {
    let html;
    if (auction.description) {
      html =
        auction.description.length >
          calculateLimitTextLength(auction.description, 150) ||
        auction.description.split(/\r\n|\r|\n/g).length >= 4
          ? "...Read More"
          : "";
    } else if (auction.descriptionShort) {
      html =
        auction.descriptionShort.length >
          calculateLimitTextLength(auction.descriptionShort, 150) ||
        auction.descriptionShort.split(/\r\n|\r|\n/g).length >= 4
          ? "...Read More"
          : "";
    } else {
      html = "";
    }
    return (
      <div className="auction-item" key={auction._id}>
        <div className="auction-img-container">
          {auction.state === "open" ? (
            <div className="enter-auction-wrapper enter-auction-wrapper-open">
              <img src={hammerImage} alt="Hammer icon" />
              <p
                className="btn enter-auction-btn"
                onClick={() => this.openLive(auction)}
              >
                <span>
                  {auction.allowAutoBid
                    ? "Set your Auto-bids"
                    : "Auction is open"}
                </span>
                <span className="icon-wrapper">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </p>
            </div>
          ) : auction.state === "live" ? (
            <div className="enter-auction-wrapper">
              <img src={hammerImage} alt="Hammer icon" />
              <p
                className="btn enter-auction-btn"
                onClick={() => this.openLive(auction)}
              >
                <span>Enter Auction</span>
                <span className="icon-wrapper">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </p>
            </div>
          ) : auction.image ? (
            <img
              className="auction-img"
              src={auction.image.url}
              alt="Auction img"
            />
          ) : (
            <span className={`no-image ${auction.kindData.type}`} alt="Lot">
              No image
            </span>
          )}
        </div>
        <div className="auction-info">
          <div className="auction-info-top">
            <div>
              <h3 className="auction-title">{auction.title}</h3>
              {auction.state === "open" && (
                <p className="auction-live-info">Auction is open</p>
              )}
              {auction.state === "live" && (
                <p className="auction-live-info">Auction is live</p>
              )}
              {auction.state !== "live" && auction.state !== "open" && (
                <div>
                  <p className="auction-live-date">Auction Starts:</p>
                  <p className="auction-live-date">
                    {momentTz(auction.liveAt).format("ddd D MMM YYYY - h.mm A")}
                  </p>
                  {isSeller() &&
                    isAllowedToAddNewLot(
                      auction.addNewLotToSpecificAgents,
                      auction.specificAgents
                    ) && (
                      <React.Fragment>
                        <p className="auction-live-date">Add New Lot Closes</p>
                        <p className="auction-live-date">
                          {momentTz(auction.editLotDisabledAt).format(
                            "ddd DD MMM YYYY - h.mm A"
                          )}
                        </p>
                      </React.Fragment>
                    )}
                  {this.showAdditionalDateInfo(auction) && (
                    <React.Fragment>
                      <p className="auction-live-date">
                        {auction.additionalDateInfo.title}:
                      </p>
                      <p className="auction-live-date">
                        {momentTz(auction.additionalDateInfo.date).format(
                          "ddd D MMM YYYY - h.mm A"
                        )}
                      </p>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
            <div className='info-btns'>
              {this.canOpenCatalogue(auction) ? (
                <Link className='btn' onClick={() => {
                  window.localStorage.setItem('upcomingAuctionsListScrollPosition', window.scrollY)
                  window.localStorage.setItem('upcomingAuctionsListCount', this.props.auctions.length)
                  window.localStorage.removeItem('upcomingLotsListScrollPosition')
                  window.localStorage.removeItem('upcomingLotsListCount')
                  const params = `/main/upcoming/${auction.kindData.title}/${auction.searchNumber}`;
                  window.parent.parent.postMessage({ customData: params }, "*");
                }} to={`/main/upcoming/${auction.kindData.title}/${auction.searchNumber}` } >
                <span>View Catalogue</span>
                <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
              </Link>
              ):(
                <span className='btn' onClick={this.openTextModal}>
                  <span>View Catalogue</span>
                  <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                </span>
              )}
              {/* {this.lotCount(auction) && (
                <Link className='btn' to={`/main/upcoming/${auction.kindData.title}/${auction.searchNumber}` } >
                  <span>View Catalogue</span>
                  <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                </Link>
                )
              } */}
              {/* <span className='btn' onClick={auction.lotCount > 0 || auction.withdrawnCount > 0 ? () => {
                this.goToLink(auction)
              } : this.openTextModal}>
                <span>View Catalogue</span>
                <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
              </span> */}

            </div>
          </div>
          <div className="auction-info-middle">
            <div className="auction-type">
              {auction.kindData.type === "cattle" && (
                <img src={cowImage} alt="Cattle" />
              )}
              {auction.kindData.type === "sheep" && (
                <img src={lambImage} alt="Sheep" className="icon-blackened" />
              )}
              {auction.kindData.type === "goat" && (
                <img src={goatImage} alt="Goat" />
              )}
            </div>
            <div className="auction-desc">
            {ReactHtmlParser(
                limitTextLength(
                  auction.descriptionShort || auction.description,
                  calculateLimitTextLength(auction.description, 150),
                  false
                )
              )}
              <span
                className="auction-readMore"
                onClick={() => {
                  window.localStorage.setItem('upcomingAuctionsListScrollPosition', window.scrollY)
                  window.localStorage.setItem('upcomingAuctionsListCount', this.props.auctions.length)
                  window.localStorage.removeItem('upcomingLotsListScrollPosition')
                  window.localStorage.removeItem('upcomingLotsListCount')
                  if (this.canOpenCatalogue(auction)) {
                    this.props.history.push(this.goToLink(auction));
                  } else {
                    this.openTextModal();
                  }
                }}
              >
                {ReactHtmlParser(html)}
              </span>
            </div>
            <div className="auction-logo">
              {auction.logo && (
                <img src={auction.logo.url} alt="Auction Logo" />
              )}
            </div>
          </div>
          <div className="auction-info-bottom">
            <span className="auction-number">Auction No. {auction.number}</span>
            <span className="status">
              Status: {this.getAuctionStatus(auction)}
            </span>
            <span className="location-state">
              {this.getAuctionState(auction)}
            </span>
            {auction.headCount > 0 && (
              <span className="heads">{auction.headCount} Head</span>
            )}
            {auction.lotCount > 0 && (
              <span className="lots">{auction.lotCount} Lots</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  canOpenCatalogue = (auction) => {
    if (auction.privateSale) {
      return true;
    }
    return auction.lotCount > 0 || auction.withdrawnCount > 0;
  };

  selectOptions(kinds, auctions, clienTag) {
    let kindArray = [];
    let agentArray = [];
    let agencyArray = [];
    let breedArray = [];
    kinds.forEach((kind) => kindArray.push(kind.type));
    auctions.forEach((auction) => {
      let data = auction.lotData;
      data.forEach(
        (lot) =>
          agentArray.push(
            `${lot.creatorData.firstName} ${lot.creatorData.lastName}`
          ) &&
          agencyArray.push(lot.creatorData.tradingName) &&
          breedArray.push(lot.publicDetails.majorBreed, ...lot.inlineDetails.sire, ...lot.inlineDetails.dam || "")
      );
    });
    agentArray = Array.from(new Set(agentArray));
    agencyArray = Array.from(new Set(agencyArray));
    breedArray = Array.from(new Set(breedArray));
    return {
      /* breed: clienTag
        ? clienTag === "Farmgate"
          ? getKindOptions(kinds, "majorBreed", { sort: true })
          : breedArray
        : getKindOptions(kinds, "majorBreed", { sort: true }), */
      breed: breedArray,
      stockCategory: getStockCategoriesByKinds(kindArray, { sort: true }),
      sex: getSexesByKinds(kindArray, { sort: true }),
      agentss: agentArray,
      agencyArray: agencyArray,
    };
  }
  rangeOptions(auctions) {
    let lots = [];
    auctions.forEach((auction) => {
      let data = auction.lotData;
      lots.push(...data);
    });
    // find min/max values only in approved lots (draft lots can have crazy values)
    lots = lots.filter((lot) => lot.approved);
    let ages = lots.map((lot) => lot.publicDetails.age.max).filter(Boolean);
    let weights = lots
      .map((lot) => lot.publicDetails.weight.average)
      .filter(Boolean);
    let ageMax = Math.round(Math.max(...ages));
    let weightMax = Math.round(Math.max(...weights));
    return {
      age: {
        min: 0,
        max: isFinite(ageMax) ? Math.max(ageMax, 0) : 0,
      },
      weight: {
        min: 0,
        max: isFinite(weightMax) ? Math.max(weightMax, 0) : 0,
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...this.state.errors };
    switch (name) {
      case 'firstName':
       errors.firstName = value.trim() === '' 
       ? 'First Name is required' : ''
       break;
       case 'lastName':
        errors.lastName = value.trim() === '' 
        ? 'Last Name is required' : ''
        break;
      case 'email':
        errors.email = !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
          ? 'Invalid email address'
          : '';
        break;
      case 'phone':
        errors.phone = !value.match(/^04[\d]{8}$/)
          ? 'Phone number should start with 04 and have 10 digits'
          : '';
        break;
      default:
        break;
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      },
      errors
    }));
  }

  renderLockedItem(auction) {
    return (
      <div className="locked-item" key={auction._id}>
        <span className="result">cancelled</span>
        {this.renderItem(auction)}
      </div>
    );
  }
  handleSubmit = (e) => {
    let {firstName,lastName,email,phone} = this.state.formData;
    e.preventDefault();
    

    if(firstName && lastName && email &&  phone) {
      this.registerInterestApi();
      this.setState({
        formData: {
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        }
      })
    } else {
      this.setState({
        showValidDataMessage : true
      })
    }

  }

   registerInterestApi = async () => {
    try {
      this.props.showSpinner(true)
      //let postData = this.state.formData;
      let postData = _pick(this.state.formData, [
        'numericId',
        'firstName',
        'lastName',
        'email',
        'phone',
      ])
      let registerInterest =  await Api.registerInterest(postData)
      this.props.showSpinner(false)
      if (registerInterest) {
        this.closeTextModal();
        this.props.showModal({
          message: registerInterest.success ? registerInterest.success : '',
          customClassName: "registerInterest-success-modal"
        })
      }
    } catch (e) {
      console.error('error', e)
      this.props.showSpinner(false)
    }

  }

  getSite(clienTag) {
    if (window.location.host.includes("test-site")) {
      return `https://test-webapp.farmgatetest.com.au/#/sign-up?wp&&${clienTag}`;
    } else if (window.location.host.includes("localhost")) {
      return `http://localhost:4000/#/sign-up?wp&&${clienTag}`;
    } else {
      return `https://live.farmgateauctions.com.au/#/sign-up?wp&&${clienTag}`;
    }
  }
  render() {
    const { auctions, locationStates } = this.props;
    let params = queryString.parse(window.location.search)
    let clienTag = params.client ? params.client : 'Farmgate'
    const site = this.getSite(clienTag);
    const { filters, showComingSoon, kinds, showLoader,errors,showValidDataMessage,showModalForm } = this.state;
    const selectOptions = this.selectOptions(kinds, auctions, clienTag);
    const ranges = this.rangeOptions(auctions);
    const {
      showMode,
      state,
      breed,
      sex,
      stockCategory,
      age,
      weight,
      selectedagents,
      agency,
    } = filters;
    const filteredAuctions = this.getFilteredAuctions(auctions, filters);
    const sydneyDate = `${momentTz(new Date()).format("D/M/YYYY")}`;
    const sydneyTime = `${momentTz(new Date()).format("h:mm A")}`;

    return this.state.showContent ? (
      <div className="auctions-wrapper">
        <Modal
          isOpen={this.state.modalTextIsOpen}
          ariaHideApp={false}
          onRequestClose={this.closeTextModal}
          overlayClassName="overlay"
          className="content"
          portalClassName="ReactModalPortal-Email"
        >
          {showModalForm ? (<div className="modal-body">
            <form className="register-interest-form" onSubmit={this.handleSubmit}>
              <label>
                First Name:
                <input
                  type="text"
                  name="firstName"
                  value={this.state.formData.firstName}
                  onChange={this.handleChange}
                />
                {errors.firstName && <span className="error">{errors.firstName}</span>}
              </label>
              <br />
              <label>
                Last Name:
                <input
                  type="text"
                  name="lastName"
                  value={this.state.formData.lastName}
                  onChange={this.handleChange}
                />
                {errors.lastName && <span className="error">{errors.lastName}</span>}
              </label>
              <br />
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={this.state.formData.email}
                  onChange={this.handleChange}
                />
              </label>
              <br />
              <label>
                Phone:
                <input
                  type="tel"
                  name="phone"
                  value={this.state.formData.phone}
                  onChange={this.handleChange}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </label>
              <br />
              <button type="submit" className='auth-button auth-button-success btn-text-centered'><span>Submit</span></button>
              <br />
              {showValidDataMessage && <p className='error'>{"Please fill all the required fields"}</p>}
            </form>
          </div>
          ) : (<div className="modal-body">
            <h2 className="modal-catalogue-title">
              Catalogue is being compiled. Check back later.
            </h2>
            <div className="buttons">
              <div className="button ok-button" onClick={this.closeTextModal}>
                <span className="button-text">OK</span>
              </div>
            </div>
          </div>)}
          
        </Modal>
        <div className="logo-reg-link" >
          <span className='icon'><img className="img" src={poweredByFarmgate} alt='Farmgate icon' /></span></div>
        <div className="top-line" >
          <span className="span-text" >New to FarmGate?</span>
          <a className="sign-uplink" href={`${site}`} rel="noopener noreferrer" target="_blank" >Click here to Register</a>
        </div>
        
        <h4 className="fg-sub-header">
          All times displayed are in Sydney Time{" "}
          <span role="img" aria-label="Sydney time">
            &#x1F556;
          </span>{" "}
          {sydneyDate}&nbsp;&nbsp;{sydneyTime}{" "}
        </h4>
        <p className="fg-paragraph">
          {isSeller()
            ? subTitles.UPCOMING_AUCTIONS
            : subTitles.UPCOMING_AUCTIONS_BUYERS}
        </p>
        <div className="auctions-controls">
          <h3 className="filters-heading">Filters</h3>
          <div className="auctions-filters-wrapper">
            <FilterKind
              categoryChoice={showMode}
              changeCategoryChoice={this.changeShowMode}
            />
            <FilterMultiSelect
              title="State"
              name="state"
              options={locationStates}
              selectedOptions={state}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <FilterMultiSelect
              title="Stock Category"
              name="stockCategory"
              options={selectOptions.stockCategory}
              selectedOptions={stockCategory}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
            />
            <FilterMultiSelect
              title="Breed"
              name="breed"
              options={selectOptions.breed}
              selectedOptions={breed}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faFilter} />}
            />
            <FilterMultiSelect
              title="Sex"
              name="sex"
              options={selectOptions.sex}
              selectedOptions={sex}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
            />
            <FilterMultiSelect
              title="Listing Agent"
              name="selectedagents"
              options={selectOptions.agentss}
              selectedOptions={selectedagents}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <FilterMultiSelect
              title="Listing Agency"
              name="agency"
              options={selectOptions.agencyArray}
              selectedOptions={agency}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <FilterRange
              title="Weight"
              name="weight"
              unit="Kg"
              description="Please type your minimum and maximum weight or use slide below."
              belowDescription="Please note: the filters are set from zero to the highest weight available in the Catalogue."
              min={ranges.weight.min}
              max={ranges.weight.max}
              value={weight}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faBalanceScale} />}
            />
            <FilterRangeAge
              title="Age"
              name="age"
              unitY="Years"
              unitM="Months"
              description="Please type your minimum and maximum age in Months or Years or use slide below."
              belowDescription="Please note: the filters are set from zero to the highest age available in the Catalogue."
              min={ranges.age.min}
              max={ranges.age.max}
              value={age}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            />
            <div
              className="btn filter-btn filter-btn-sm clear-all-btn"
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className="icon-wrapper icon-danger">
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          </div>
        </div>
        <div className="auctions-list list">
          {this.groupAuctionsByDate(filteredAuctions).map((group) => {
            if (this.isFutureAuctionExist(group.auctions)) {
              return (
                <section className="auctions-group" key={group.title}>
                  <p className="group-title">{group.title}</p>
                  {group.auctions.map((auction) => {
                    if (
                      auction.state === "future" ||
                      auction.state === "open" ||
                      auction.state === "live"
                    ) {
                      if (auction.cancelled) {
                        return this.renderLockedItem(auction);
                      } else {
                        return this.renderItem(auction);
                      }
                    } else {
                      return "";
                    }
                  })}
                </section>
              );
            } else {
              return "";
            }
          })}
          {!filteredAuctions.length && ( showComingSoon  ) ? (
            <div className="auctions-empty">
              <FontAwesomeIcon icon={faClock} />
              <p>Coming Soon</p>
            </div>
          ) : showLoader ? <div className="auctions-empty">
          <FontAwesomeIcon icon={faSpinner} />
          <p>Loading...</p>
        </div> : ''}
        {!filteredAuctions.length && (
            <div><p className="register-interest-text">
              {subTitles.INTEREST_OF_SALE}
            </p>
              <div className="register-interest-div">
                <span className='btn' onClick={(e) => {
                        this.openTextModal(e,true)
                 }}>
                  <span>REGISTER INTEREST</span>
                  <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
                </span>
              </div>
            </div>)}

        </div>
      </div>
    ) : null;
  }
}

AuctionLits.propTypes = {
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
AuctionLits.defaultProps = {
}

export default withRouter(AuctionLits)