//dev
// export const BASE_URL = 'https://dev-api.farmgatetest.com.au/api/v1/'
// export const SOCKET_URL = 'https://dev-api.farmgatetest.com.au/'
// export const WEB_APP = 'https://live.farmgateauctions.com.au/'
// export const PLUGIN_APP_URL = 'https://site-upcoming-auctions.farmgateauctions.com.au'
// export const APP_RELEASE = 'development'
// export const MODE = 'dev'

//test
// export const BASE_URL = 'https://test-api.farmgatetest.com.au/api/v1/'
// export const SOCKET_URL = 'https://test-api.farmgatetest.com.au/'
// export const WEB_APP = 'https://test-webapp.farmgatetest.com.au/'
// export const PLUGIN_APP_URL = 'https://test-site-upcoming-auctions.farmgateauctions.com.au/'
// export const APP_RELEASE = 'test'
// export const MODE = 'test'

// export const BASE_URL = 'http://localhost:3000/api/v1/'
// export const SOCKET_URL = 'http://localhost:3000/'
// export const WEB_APP = 'https://live.farmgateauctions.com.au/'
// export const PLUGIN_APP_URL = 'https://site-upcoming-auctions.farmgateauctions.com.au'
// export const APP_RELEASE = 'development'
// export const MODE = 'dev'


//live
export const WEB_APP = 'https://live.farmgateauctions.com.au/'
export const BASE_URL = 'https://fga.farmgateauctions.com.au/api/v1/'
export const SOCKET_URL = 'https://fga.farmgateauctions.com.au/'
export const PLUGIN_APP_URL = 'https://site-upcoming-auctions.farmgateauctions.com.au'
export const APP_RELEASE = 'development'
export const MODE = 'live'