import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPrint,
  faArrowDown
} from '@fortawesome/fontawesome-free-solid'

import ImageCarousel from '../../ImageCarousel/ImageCarouselContainer'
import DetailedInfo from '../../DetailedInfo/DetailedInfoContainer'
import LotDocuments from '../../LotDocuments/LotDocuments'
import EmailModalLot from '../../Modals/EmailModalLotContainer'
import BackButton from '../../BackButton/BackButton'
import WatchButton from '../../WatchButton/WatchButtonContainer'
import Api from '../../../services/api'
import {
  momentTz,
  parseQueryString
} from '../../../utils'
import { BASE_URL } from '../../../config'
import SocialButtons from '../../SocialButtons/SocialButtons'
import { Calculator } from '../../Calculator/Calculator'
import { getLotNumber } from '../../../utils/Auctions'

class LotDetails extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      auction: null,
      lot: null,
      modalIsOpen: false
    }
    this.cancelSource = axios.CancelToken.source()
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.from = parseQueryString(this.props.location.search).from
    this.allFields = false
    this.mode = 'full-preview'
  }

  componentDidMount () {
    window.scroll(0, 0)
    if (!this.props.location.pathname.includes('agent-comment')) {
      window.parent.parent.postMessage(`Scroll up`, '*')
    }
    if (this.props.location.pathname.includes('LC') || this.props.location.pathname.includes('LS') || this.props.location.pathname.includes('LG')) {
      this.getData()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if ((this.props.match.params.auctionNumber !== prevProps.match.params.auctionNumber) ||
      (this.props.match.params.lotNumericId !== prevProps.match.params.lotNumericId)) {
      this.cancelSource.cancel()
      this.cancelSource = axios.CancelToken.source()
      if (this.props.location.pathname.includes('LC') || this.props.location.pathname.includes('LS')) {
        this.getData()
      }
    } else if (this.props.match.params.kindTitle !== prevProps.match.params.kindTitle) {
      this.checkTitle()
    }
  }

  componentWillUnmount () {
    this.cancelSource.cancel()
    if (this.props.history.action === 'PUSH') {
      this.props.history.goBack()
    } else {
      this.props.history.replace(`/main/upcoming/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
    }
  }

  checkTitle () {
    const kindTitle = this.state.auction.kindData.title
    if (kindTitle.toLowerCase() !== this.props.match.params.kindTitle.toLowerCase()) {
      // if wrong kindTitle
      this.props.history.replace(`/main/upcoming/${kindTitle}/${this.props.match.params.auctionNumber}/${this.props.match.params.lotNumericId}`)
    }
  }

  getData () {
    this.props.showSpinner(true)
    if (this.props.match.params.lotNumericId && this.props.match.params.lotNumericId.includes('LC')) {
      this.props.match.params.lotNumericId = 'LC' + this.props.match.params.lotNumericId.split('LC')[1]
    }
    if (this.props.match.params.lotNumericId && this.props.match.params.lotNumericId.includes('LS')) {
      this.props.match.params.lotNumericId = 'LS' + this.props.match.params.lotNumericId.split('LS')[1]
    }
    if (this.props.match.params.lotNumericId && this.props.match.params.lotNumericId.includes('LG')) {
      this.props.match.params.lotNumericId = 'LG' + this.props.match.params.lotNumericId.split('LG')[1]
    }
    Api.getLot(
      this.props.match.params.lotNumericId,
      {
        cancelToken: this.cancelSource.token,
        mode: this.mode,
        trackView: true
      }
    ).then((lot) => {
      Api.getAuction(lot.lot.auctionData.searchNumber, { cancelToken: this.cancelSource.token })
        .then((auction) => {
          this.props.showSpinner(false)
          this.setState({
            auction: auction.auction,
            lot: lot.lot
          }, () => {
            if (this.props.location.state && this.props.location.state.scroll) {
              window.scroll(0, this.props.location.state.scroll)
            } else {
              if (this.props.history.action !== 'POP') {
                window.scroll(0, 0)
              }
            }
            if (this.state.lot.draft) {
            // check if a lot is in draft, leave page if it is
              this.props.history.replace(`/main/upcoming/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
            } else {
              this.checkTitle()
            }
          })
        })
    }).catch((error) => {
      console.log(error)
      setTimeout(() => {
        this.getData()
        console.log('reload Data')
      }, 100)
    })
  }

  getLotLocation () {
    const addresses = []
    const possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      const str = this.state.lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ').toUpperCase()
  }

  goBackName () {
    if (this.from) {
      if (this.from === 'watchList') {
        return 'Back to Watch List'
      }
    }
    return 'Back to Catalogue'
  }

  goBackLink () {
    if (this.from) {
      if (this.from === 'watchList') {
        return '/main/watchlist'
      }
    }
    return `#/main/upcoming/${this.state.auction.kindData.title}/${this.state.auction.searchNumber}`
  }

  openModal () {
    window.parent.parent.postMessage(`modalOpen`, '*')
    this.setState({
      modalIsOpen: true
    })
  }

  closeModal () {
    window.parent.parent.postMessage(`modalClose`, '*')
    this.setState({
      modalIsOpen: false
    })
  }

  pdfUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/full-details?mode=print`
  }

  downloadReportUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/full-details`
  }
  averageWeightText (lot) {
    if (lot.optiweighAllowed) {
      return lot.weightGain !== 0 ? `${lot.weightGain.toFixed(2)}kg avdwtg` : '-'
    } else {
      return lot.publicDetails.weight.average !== 0 ? `${Math.round(lot.publicDetails.weight.average)}kg ass/wt` : '-'
    }
  }

  render () {
    const { auction, lot } = this.state
    if (!auction || !lot) {
      return <div />
    }
    const shareMessage = `Lot ${lot.number}: ${lot.publicDetails.title} ${
      lot.publicDetails.weight.curfew
    }kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'} ${lot.publicDetails.location.town} ${
      lot.publicDetails.location.state
    }`
    const isTestWP = window.location.host.includes('test-site') ? 'test-wp.' : ''
    const shareUrl = `https://${isTestWP}farmgateauctions.com.au/upcoming-auctions/?${lot.kindData.title}/${
      lot.auctionData.searchNumber
    }/${lot.searchNumericId}`

    return (
      <div className='lot-wrapper'>
        <EmailModalLot
          openModal={this.openModal}
          closeModal={this.closeModal}
          isOpen={this.state.modalIsOpen}
          lot={lot}
          owner={this.allFields}
        />
        <div className='lot-menu'>
          <div className='left'>
            <p className='fg-header watch-header'>{this.state.auction.title}</p>
            <p className='fg-secondary-header'>Auction No. {this.state.auction.number}</p>
            <p className='auction-live-date'>{momentTz(this.state.auction.liveAt).format('dddd D MMMM YYYY - h.mm A')}</p>
            <p className='fg-paragraph'>{this.state.auction.description}</p>
          </div>
          <div className='right'>
            <SocialButtons shareMessage={shareMessage} shareUrl={shareUrl} />
          </div>
        </div>
        <div className='top-btns results results-flex'>
          <Fragment>
            <BackButton onPress={() => {
              window.parent.parent.postMessage(this.goBackName(), "*");
              if (this.props.history.action === 'PUSH') {
                this.props.history.goBack()
              } else {
                this.props.history.replace(`/main/upcoming/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
              }
            }} name={this.goBackName()} removeLotParamInWordPress />
            <span className='btn btn-flex' onClick={this.openModal}>
              <span>
                <span className='btn-title'>Email</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
            </span>
            <a className='btn btn-flex' href={this.downloadReportUrl()}>
              <span>
                <span className='btn-title'>Download</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowDown} /></span>
            </a>
            <a rel='noopener noreferrer' className='btn btn-flex' href={this.pdfUrl()} target='_blank'>
              <span>
                <span className='btn-title'>Print</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
            </a>
            {
              lot.approved
                ? <WatchButton lotId={lot._id} bigButton />
                : null
            }
          </Fragment>
        </div>
        <hr className='separator' />
        <div className='lot-info-wrapper'>
          <div className='left-side'>
            <div className='lot-info'>
              <div className='lot-info-top'>
                <div className='title'>
                  <p>
                    {getLotNumber(auction, lot)}
                    {/* { lot.createdBy === this.props.userId &&
                  <Fragment>
                    &nbsp;-&nbsp;
                    <span className='icon-wrapper'>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span> My Listing</span>
                  </Fragment>
                } */}
                  </p>
                </div>
                <div className='location'>
                  <p className='lot-location'>{this.getLotLocation()}</p>
                </div>
              </div>
              <div className='lot-title-wrapper'>
                <p className='lot-title'>{`${lot.publicDetails.title} - ${lot.publicDetails.summary.age} - ${lot.additionalTitle}`}</p>
                <div className='company-logo-wrapper'>
                  {
                    lot.creatorData.logo &&
                    <img src={lot.creatorData.logo.url} alt='Company logo' />
                  }
                </div>
              </div>
              {lot.usefulLinks && lot.usefulLinks.length
                ? <div id='useful-links-details' className='lots-controls'>
                  <h3 className='filters-heading'>Useful links:</h3>
                  <div className='lots-links-wrapper'>
                    {lot.usefulLinks.map(link => {
                      if (link.url) {
                        return (
                          <a target='_blank' rel='noopener noreferrer' href={link.url} className='link'>{link.description || link.url}</a>
                        )
                      }
                      return null
                    })}
                  </div>
                </div> : null }
              <div className='lot-title-weights'>
                <p className='title-weight'>{this.averageWeightText(lot)}</p>
                <p className='title-weight curfew'>{`${lot.publicDetails.weight.curfew !== 0 ? `${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'}` : '-'}`}</p>
              </div>
              {lot.inlineDetails.assessorType &&
                <div className='lot-title-weights'>
                  <p className='title-weight assessor-type'>{lot.inlineDetails.assessorType}</p>
                </div>
              }
              <ImageCarousel lot={lot} lotId={lot._id} media={lot.media} />
              <LotDocuments documents={lot.documents} />
              <DetailedInfo lot={lot} allFields={this.allFields} agentComment={this.props.location.pathname.includes('agent-comment')} />
            </div>
          </div>
          {auction.showCalculator && <Calculator />}
        </div>
      </div>
    )
  }
}

LotDetails.propTypes = {
  auction: PropTypes.object,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

LotDetails.defaultProps = {
}

export default LotDetails
