import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faFilePdf } from '@fortawesome/fontawesome-free-solid'
import { isElementInsideOf } from '../../utils'
import './styles.css'

class PrintCatalogueBtn extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.open = this.open.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)

    this.innerContainer = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.addEventListener('click', this.handleDocumentClick)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  open () {
    let state = !this.state.isOpen
    this.setState({ isOpen: state })
  }

  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.innerContainer.current)) {
      return
    }
    this.setState({ isOpen: false })
  }

  render () {
    return (
      <div className='print-wrapper'>
        <div className='btn print-btn' onClick={this.open.bind(this)}>
          <span>{this.props.name}</span>
          <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
        </div>
        <div className={`inner-wrapper ${this.state.isOpen ? 'active' : ''}`}>
          <div>
            <a className='item' href={this.props.summaryUrl} target='_blank' rel='noopener noreferrer'>
              <span>Summary Catalogue</span>
              <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
            </a>
            <a className='item' href={this.props.fullDetailsUrl} target='_blank' rel='noopener noreferrer'>
              <span>Lots in Full Detail</span>
              <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

PrintCatalogueBtn.defaultProps = {
  name: 'Print Catalogue',
  summaryUrl: '/',
  fullDetailsUrl: '/'
}

PrintCatalogueBtn.propTypes = {
  name: PropTypes.string,
  summaryUrl: PropTypes.string,
  fullDetailsUrl: PropTypes.string
}

export default PrintCatalogueBtn
