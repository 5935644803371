import React, { PureComponent } from 'react'
import { Link, withRouter, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import axios from 'axios'
import moment from 'moment'
import memoize from 'memoize-one'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'react-simple-tooltip'
import { css } from 'styled-components'
import {
  faEnvelope,
  // faUser,
  faSearch,
  faFilter,
  faCaretDown,
  faTimes,
  faBalanceScale, faArrowRight
} from '@fortawesome/fontawesome-free-solid'
import { faCompass, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import WatchButton from '../../WatchButton/WatchButtonContainer'
import ImageCarousel from '../../ImageCarousel/ImageCarouselContainer'
import EmailModalAuction from '../../Modals/EmailModalAuctionContainer'
import SortDropdown from '../../SortDropdown/SortDropdown'
import DownloadCatalogueBtn from '../../DownloadCatalogueBtn/DownloadCatalogueBtn'
import PrintCatalogueBtn from '../../PrintCatalogueBtn/PrintCatalogueBtn'
import LotItemWithdrawn from '../../LotItemWithdrawn/LotItemWithdrawn'
import BackButton from '../../BackButton/BackButton'
import FilterMultiSelect from '../../FilterMultiSelect/FilterMultiSelect'
import FilterRange from '../../FilterRange/FilterRange'
import FilterRangeAge from '../../FilterRangeAge/FilterRangeAge'
import Api from '../../../services/api'
import ServerTime from '../../../services/ServerTime'
// import { getKindOptions } from '../../../utils/FindFieldKind'
import { momentTz, parseQueryString, limitTextLength, calculateLimitTextLength } from '../../../utils'
import { filterLots, sortLots } from '../../../utils/Filter'
import { getLotNumber, isAvailableAuction } from '../../../utils/Auctions'
import { getSexesByKinds, getStockCategoriesByKinds } from '../../../utils/Lots'
import { BASE_URL } from '../../../config'
import { Calculator } from '../../Calculator/Calculator'
import LotBottomInfo from '../../LotBottomInfo/LotBottomInfo'
import { LotBoxContainer, LotBoxValue,TooltipContainer } from '../../LotBox/styled'


class LotsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      auction: null,
      listType: 'list',
      redirectAgentCommentUrl: '',
      lots: [],
      page: 1,
      limit: 10,
      hasMore: true,
      fetching: false,
      requestFinish: true,
      sortType: 'number',
      sortDirection: 1,
      filters: {
        screen: this.getScreen(),
        breed: this.getSavedFilterValue('breed', []),
        stockCategory: this.getSavedFilterValue('stockCategory', []),
        sex: this.getSavedFilterValue('sex', []),
        age: this.getSavedFilterValue('age', []),
        weight: this.getSavedFilterValue('weight', []),
        location: this.getSavedFilterValue('location', [])
      },
      totalTable: []
    }
    this.cancelSource = axios.CancelToken.source()
    this.getDisplayData = memoize(this.getDisplayData)
    this.selectOptions = memoize(this.selectOptions)
    this.rangeOptions = memoize(this.rangeOptions)
    this.changeListType = this.changeListType.bind(this)
    this.changeSortChoice = this.changeSortChoice.bind(this)
    this.changeSortDirection = this.changeSortDirection.bind(this)
    this.openLive = this.openLive.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.filterChangeHandler = this.filterChangeHandler.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.from = parseQueryString(this.props.location.search).from
    this.filterOptions = [
      'Lot Number',
      'Average Age',
      'Assessed Live Weight',
      'Sex',
      'Breed',
      'Stock Category'
    ]
  }

  componentDidMount() {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    window.parent.parent.postMessage(`Update link`, '*')
    window.parent.parent.postMessage(`Restore scroll position`, '*')
    this.checkProps()
    this.getData()
    this.getAuction()
  }
  getAuction () {
    Api.getAuction(this.props.match.params.auctionNumber, { cancelToken: this.cancelSource.token })
      .then((data) => {
        this.setState({
          auction: data.auction
        })
      }
      )
  }
  componentDidUpdate (prevProps, prevState, snapshot) {
    let scrollPosition = parseInt(window.localStorage.getItem('upcomingLotsListScrollPosition')) || 0
    let countOfLots = parseInt(window.localStorage.getItem('upcomingLotsListCount')) || 0
   if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp('filters', [this.state.filters], 'user')
    }
    if (countOfLots !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.state.lots.length === countOfLots) {
        window.localStorage.removeItem('upcomingLotsListScrollPosition')
        window.localStorage.removeItem('upcomingLotsListCount')
      }
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel()
  }

  getScreen() {
    return `upcomingLotsList-${this.props.match.params.auctionNumber}`
  }

  getSavedFilterValue(key, defaultValue = []) {
    const screen = this.getScreen()
    const thisFilter = this.props.filters.find(filterSet => filterSet.screen === screen)
    let value
    if (thisFilter) {
      value = thisFilter[key]
    }
    return value || defaultValue
  }

  goBackName() {
    if (this.from) {
      if (this.from === 'watchList') {
        return 'Back to Watch List'
      }
    }
    return 'Back to Auctions'
  }

  goBackLink() {
    if (this.from) {
      if (this.from === 'watchList') {
        return '/main/watchlist'
      }
    }
    return `#/main/upcoming`
  }

  checkProps() {
    console.log(this.state.auction)
    if (!this.state.auction) {
      // if wrong auction
      const kindTitle = window.location.href.includes('Cattle') ? 'Cattle' : 'Sheep'
      this.props.history.replace(`/main/upcoming/${kindTitle}/${this.props.match.params.auctionNumber}`)
    } else {
      const kindTitle = this.state.auction.kindData.title
      if (kindTitle.toLowerCase() !== this.props.match.params.kindTitle.toLowerCase()) {
        // if wrong kindTitle
        this.props.history.replace(`/main/upcoming/${kindTitle}/${this.props.match.params.auctionNumber}`)
      }
    }
  }

  async getData() {
    this.props.showSpinner(true)
    if (this.props.match.params.auctionNumber && this.props.match.params.auctionNumber.includes('AC')) {
      this.props.match.params.auctionNumber = 'AC' + this.props.match.params.auctionNumber.split('AC')[1]
    }
    if (this.props.match.params.auctionNumber && this.props.match.params.auctionNumber.includes('AS')) {
      this.props.match.params.auctionNumber = 'AS' + this.props.match.params.auctionNumber.split('AS')[1]
    }
    const data = await Api.getLotsByAuction(
      this.props.match.params.auctionNumber,
      { state: ['future', 'open', 'live', 'futureInLive'], cancelToken: this.cancelSource.token }
    )
    if (data && data.totalTable) {
      this.setState({
        totalTable: data.totalTable,
        lots:data.lots
      })
    }
    // if (data && !data.cancel) {
    //   this.props.mergeStateProp('lots', data.lots, 'data')
    // }
    const height = document.getElementsByClassName('app-route')[0].offsetHeight
    window.parent.parent.postMessage(`iframe height=${height || 920}`, '*')
    this.props.showSpinner(false)
  }

  getDisplayData(lots, filters, sortChoice, sortDirection) {
    const filteredLots = filterLots(lots, filters)
    return sortLots(filteredLots, sortChoice, sortDirection)
  }

  selectOptions(kinds, auction, locationStates, lots) {
    const kindObj = kinds.find(kind => kind._id === auction.kind)
    let breedArray = []
    lots.forEach((lot) => {
      if(lot.publicDetails) {
        breedArray.push(lot.publicDetails.majorBreed)
      } 
      if(lot.inlineDetails) {
        breedArray.push(...lot.inlineDetails.sire, ...lot.inlineDetails.dam || "")
      }
      
    })
    breedArray = Array.from(new Set(breedArray));
    return { 
      //breed: getKindOptions(kindObj, 'majorBreed', { sort: true }),
      breed: breedArray,
      stockCategory: getStockCategoriesByKinds(kindObj.type, { sort: true }),
      sex: getSexesByKinds(kindObj.type, { sort: true }),
      location: locationStates
    }
  }

  rangeOptions(lots) {
    const ages = lots.map(lot => lot.publicDetails.age.max).filter(Boolean)
    const weights = lots.map(lot => lot.publicDetails.weight.max).filter(Boolean)
    const ageMax = Math.round(Math.max(...ages))
    const weightMax = Math.round(Math.max(...weights))
    return {
      age: {
        min: 0,
        max: isFinite(ageMax) ? Math.max(ageMax, 0) : 0
      },
      weight: {
        min: 0,
        max: isFinite(weightMax) ? Math.max(weightMax, 0) : 0
      }
    }
  }

  getLotLocation(lot) {
    const addresses = []
    const possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      const str = lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }

  changeListType(type) {
    this.setState({
      listType: type
    })
  }

  changeSortChoice(sortChoice) {
    this.props.changeStateProp('lotSort', {
      choice: sortChoice,
      direction: this.props.sortDirection
    }, 'main')
  }

  changeSortDirection() {
    this.props.changeStateProp('lotSort', {
      choice: this.props.sortChoice,
      direction: this.props.sortDirection === 'up' ? 'down' : 'up'
    }, 'main')
  }
  
  goToLotLink (lot) {
    return `/main/upcoming/${this.state.auction.kindData.title}/${this.state.auction.searchNumber}/${lot.searchNumericId}`
  }

  addNewLink() {
    return `/main/add/new/${this.state.auction.kindData.title}/${this.state.auction.searchNumber}`
  }

  showButton() {
    if (this.state.auction.viewDetailsDays || this.state.auction.viewDetailsDays === 0) {
      return moment(ServerTime.getTime()).add(this.state.auction.viewDetailsDays, 'd').isAfter(this.state.auction.liveAt)
    } else {
      return moment(ServerTime.getTime()).add(10, 'd').isAfter(this.state.auction.liveAt)
    }
  }

  canAddLot(auction) {
    return isAvailableAuction(auction)
  }

  navigateToAddLot(auction) {
    switch (this.props.userStatus) {
      case 'partially-approved':
        this.props.showModal({
          message: 'Please complete your profile to Add Lot',
          buttons: [
            {
              text: 'Cancel'
            },
            {
              text: 'Update',
              onPress: () => {
                this.props.history.push(`/main/profile`)
              }
            }
          ]
        })
        break
      case 'partially-approved:updated':
        this.props.showModal({
          message: 'Your application is under review and should be approved in the next 24-hours. Please contact us if you need immediate access to Add a Lot to an Upcoming Auction.',
          buttons: [
            {
              text: 'OK'
            }
          ]
        })
        break
      default:
        this.props.history.push(this.addNewLink(auction))
        break
    }
  }

  openModal() {
    window.parent.parent.postMessage(`modalOpen`, '*')
    this.setState({
      modalIsOpen: true
    })
  }

  closeModal() {
    window.parent.parent.postMessage(`modalClose`, '*')
    this.setState({
      modalIsOpen: false
    })
  }

  afterOpenModal() {
    // console.log('modal opened') custom animation here?
  }

  openLive() {
    if (window.location.host.includes('test-site')) {
      window.open(`https://test-webapp.farmgatetest.com.au/#/auction-live/${this.state.auction.searchNumber}`)
    } else {
      window.open(`https://live.farmgateauctions.com.au/#/auction-live/${this.state.auction.searchNumber}`)
    }
  }

  filterChangeHandler(filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value }
      })
    })
  }

  clearFilter() {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: 'all' },
        breed: { $set: [] },
        stockCategory: { $set: [] },
        sex: { $set: [] },
        age: { $set: [] },
        weight: { $set: [] },
        location: { $set: [] }
      }
    }))
  }

  renderInfoTop(lot, auction) {
    return (
      <div className='lot-info-top'>
        <p className='lot-number'>
          {getLotNumber(auction, lot)}
          {/* {lot.createdBy === this.props.userId &&
            (
              this.state.listType === 'list'
                ? (
                  <span className='my-listing'>
                  -
                    <span className='icon-wrapper'>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span className='my-listing-text'>
                    My Listing
                    </span>
                  </span>
                )
                : (
                  <span className='my-listing'>
                    <span className='icon-wrapper'>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </span>
                )
            )
          } */}
        </p>
        <p className='lot-location'>{this.getLotLocation(lot)}</p>
      </div>
    )
  }

  render() {
    const { kinds,locationStates, sortChoice, sortDirection } = this.props
    const { filters,auction,lots } = this.state
    if (!auction) {
      return <div></div>
    }
    
    const { breed, stockCategory, sex, age, weight, location } = filters
    const displayLots = this.getDisplayData(lots, filters, sortChoice, sortDirection)
    const selectOptions = this.selectOptions(kinds, auction, locationStates, lots)
    const ranges = this.rangeOptions(lots)
    let isFiltersApplied = displayLots.length !== lots.length;
    return (
      <div className='lots-wrapper'>
        {this.state.redirectAgentCommentUrl ? <Redirect
          to={{
            pathname: this.state.redirectAgentCommentUrl,
            state: { scroll: 10000 }
          }}
        /> : ''}
        <EmailModalAuction
          openModal={this.openModal}
          closeModal={this.closeModal}
          afterOpenModal={this.afterOpenModal}
          isOpen={this.state.modalIsOpen}
          auction={auction}
        />
        <div className='top-header'>
          <div className='left'>
            <h1 className='auction-title'> {auction.title}</h1>
            <h2 className='auction-subtitle'>Auction No. {auction.number}</h2>
            <p className='auction-live-date'>
              {momentTz(auction.liveAt).format('dddd D MMMM YYYY, h.mm A')}
              {auction.state === 'open' &&
                <span className='status status-open'> AUCTION IS OPEN</span>
              }
              {auction.state === 'live' &&
                <span className='status status-live'> AUCTION IS LIVE</span>
              }
            </p>
            <p className='auction-desc'>{auction.description}</p>
          </div>
          <div className='right'>
            <div className='auction-logo'>
              {auction.logo && <img src={auction.logo.url} alt='Auction Logo' />}
            </div>
          </div>
        </div>
        <div className='top-btns upcoming'>
          <div className='left'>
            <BackButton onPress={() => {
              window.parent.parent.postMessage(this.goBackName(), "*");
              this.props.history.goBack()
            }} name={this.goBackName()} />
            
            <div className='btn'
              onClick={this.openModal}>
              <span>Email Catalogue</span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
            </div>
            <DownloadCatalogueBtn
              summaryUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/auction-summary?type=pdf`}
              fullDetailsUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/full-details`}
            />
            <PrintCatalogueBtn
              summaryUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/auction-summary?type=pdf&mode=print`}
              fullDetailsUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/full-details?type=pdf&mode=print`}
            />
            {
              (auction.state === 'open' || auction.state === 'live') && (
                <div className='btn enter-auction-btn' onClick={this.openLive}>
                  <span>Enter Auction</span>
                  <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
                </div>
              )
            }
          </div>
        </div>
        {auction.usefulLinks && auction.usefulLinks.length
          ? <div id='useful-links' className='lots-controls'>
            <h3 className='filters-heading'>Useful links:</h3>
            <div className='lots-links-wrapper'>
              {auction.usefulLinks.map(link => {
                if (link.url) {
                  return (
                    <a target='_blank' rel='noopener noreferrer' href={link.url} className='link'>{link.description || link.url}</a>
                  )
                }
                return null
              })}
            </div>
          </div> : null
        }
        <div className='lots-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='lots-filters-wrapper'>
            <FilterMultiSelect
              title='Breed'
              name='breed'
              options={selectOptions.breed}
              selectedOptions={breed}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faFilter} />}
            />
            <FilterMultiSelect
              title='Sex'
              name='sex'
              options={selectOptions.sex}
              selectedOptions={sex}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
            />
            <FilterMultiSelect
              title='Stock Category'
              name='stockCategory'
              options={selectOptions.stockCategory}
              selectedOptions={stockCategory}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
            />
            <FilterRangeAge
              title='Age'
              name='age'
              unitY='Years'
              unitM='Months'
              description='Please type your minimum and maximum age in Months or Years'
              min={ranges.age.min}
              max={ranges.age.max}
              value={age}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            />
            <FilterRange
              title='Weight'
              name='weight'
              unit='Kg'
              description='Please type your minimum and maximum weight'
              min={ranges.weight.min}
              max={ranges.weight.max}
              value={weight}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faBalanceScale} />}
            />
            <FilterMultiSelect
              title='Location'
              name='location'
              options={selectOptions.location}
              selectedOptions={location}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCompass} />}
            />
            <div
              className='btn filter-btn filter-btn-sm clear-all-btn'
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
            </div>
          </div>
        </div>
        <div className='catalog-settings'>
          <SortDropdown
            sortOptions={this.filterOptions}
            sortChoice={this.props.sortChoice}
            changeSortChoice={this.changeSortChoice}
            sortDirection={this.props.sortDirection}
            changeSortDirection={this.changeSortDirection}
          />
          <h2 className='catalog-header'>Catalogue</h2>
          <div className='view-modes'>
            <div className={`view-mode-button view-mode-list${this.state.listType === 'list' ? ' active' : ''}`}
              onClick={() => this.changeListType('list')} />
            <div className={`view-mode-button view-mode-grid${this.state.listType === 'grid' ? ' active' : ''}`}
              onClick={() => this.changeListType('grid')} />
          </div>
        </div>
        <>
          {this.state.totalTable.length > 0
            ? <div className='table-wrapper table-auction-results'>
              <div className='table-header'>
                <div>Sex</div>
                <div>Head</div>
                <div>Lots</div>
              </div>
              {this.state.totalTable.map((el, index) => {
                return (
                  <span className={`table-row ${this.state.totalTable.length === 1 && 'single-row'}`} key={index} >
                    <div>{el.sex}</div>
                    <div>{el.counts && el.counts.heads}</div>
                    <div>{el.counts && el.counts.total}</div>
                  </span>
                )
              })}
            </div>
            : null}
        </>
        <div className='lots'>
          <div className='left-side'>
            <div className={this.state.listType === 'grid' ? 'lots-list grid' : 'lots-list list'}>
              {displayLots.map(lot => {
                if (lot.withdrawn) {
                  return (
                    <LotItemWithdrawn lot={lot} key={lot._id} />
                  )
                }
                return (
                  <div className='lot-item' key={lot._id}>
                    {
                      this.state.listType === 'grid' && this.renderInfoTop(lot, auction)
                    }
                    <div className='lot-img-container'>
                      <ImageCarousel
                        lot={lot}
                        lotList={displayLots}
                        key={this.state.listType}
                        lotId={lot._id}
                        media={lot.media}
                        kindType={auction.kindData.type}
                        listType={this.state.listType}
                        preview />
                    </div>
                    <div className='lot-info'>
                      {
                        this.state.listType === 'list' && this.renderInfoTop(lot, auction)
                      }
                      <div className='lot-info-middle'>
                        <div className='left'>
                          <p className='lot-title'>{`${lot.publicDetails.title} ${lot.publicDetails.summary.age} ${lot.additionalTitle}`}</p>
                          <p className='lot-comments'>{`${limitTextLength(lot.publicDetails.agentComment, calculateLimitTextLength(lot.publicDetails.agentComment, 420), false)} `}
                            <span className='lot-readMore' onClick={() => {
                              this.setState({
                                redirectAgentCommentUrl: this.goToLotLink(lot)
                              }, () => {
                                console.log('redirectAgentCommentUrl==', this.state.redirectAgentCommentUrl)
                              })
                            }}>
                              {(lot.publicDetails.agentComment.length > calculateLimitTextLength(lot.publicDetails.agentComment, 420) || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4) ? '...Read More' : ''}
                            </span>
                            {/* <Link className='lot-readMore' to={`/${auction.kindData.title}/${auction.searchNumber}/${lot.searchNumericId}/agent-comment`}>
                            {(lot.publicDetails.agentComment.length > calculateLimitTextLength(lot.publicDetails.agentComment, 420) || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4) ? '...Read More' : ''}
                                </Link> */}
                          </p>
                        </div>
                        <div className='info-btns'>
                          <WatchButton lotId={lot._id} bigButton list />
                          {
                            this.showButton()
                              ? (
                                <Link className='btn' onClick={() => {
                                  window.localStorage.setItem('upcomingLotsListScrollPosition', window.scrollY)
                                  window.localStorage.setItem('upcomingLotsListCount', lots.length)
                                  const params = `/main/upcoming/${auction.kindData.title}/${auction.searchNumber}/${lot.searchNumericId}`;
                                  window.parent.parent.postMessage({ customData: params }, "*");
                                }} to={`/main/upcoming/${auction.kindData.title}/${auction.searchNumber}/${lot.searchNumericId}`}>
                                  <span>View Details</span>
                                  <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faSearch} /></span>
                                </Link>

                              )
                              : null
                          }
                        </div>
                      </div>
                      <div className='lot-info-bottom'>
                        <div className='info-wrap'>
                          <LotBottomInfo lot={lot} />
                        </div>
                        <div className='assessor-type-wrapper' style={{ alignSelf: this.state.listType === 'grid' ? 'center' : 'flex-start' }}>
                                {lot.inlineDetails.assessorType &&
                                  <TooltipContainer>
                                    <Tooltip customCss={css`
                                   white-space: nowrap;
                                   padding-right: 11px;
                                 `} content={lot.inlineDetails.assessorType === 'Vendor Assessed' ? 'This lot has been assessed by an accredited vendor' : 'This lot has been assessed by an accredited agent'} placement='left' background={'#2e2e2e'} radius={5} padding={8} >
                                      <LotBoxContainer type={'assessorType'}>
                                        <LotBoxValue>{lot.inlineDetails.assessorType}</LotBoxValue>
                                      </LotBoxContainer>
                                    </Tooltip>
                                  </TooltipContainer>
                                }
                                <div className='company-logo-wrapper'>
                                  {
                                    lot.creatorData.logo &&
                                    <img src={lot.creatorData.logo.url} alt='Company logo' />
                                  }
                                </div>
                              </div>
                      </div>
                    </div>
                  </div>)
              })}
              {displayLots.length % 3 === 2 ? (
                <div className='lot-item dump' key='dump' />
              ) : (false)}
            </div>
          </div>
          {auction.showCalculator && <Calculator />}
        </div>

        {!displayLots.length && (
          <div className='no-data-placeholder'>
            <div className='message'>
             {isFiltersApplied ? "There are no results for your search." : "Catalogue is being compiled. Check back later."} 
            </div>
          </div>
        )}
      </div>
    )
  }
}

LotsList.propTypes = {
  kinds: PropTypes.array.isRequired,
  auction: PropTypes.object,
  lots: PropTypes.array.isRequired,
  sortChoice: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  mergeStateProp: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
}

LotsList.defaultProps = {
}

export default withRouter (LotsList)
